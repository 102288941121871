import { combineReducers } from "redux";

import signup from "./signup";
import reps from "./reps";
import map from "./map";
import rentalForm from "./rentalForm";
import requestRep from "./requestRep";
import user from "./user";
import ownRentalRecords from "./ownRentalRecords";
import admin from "./admin";

export default combineReducers({
  signup,
  reps,
  map,
  rentalForm,
  requestRep,
  user,
  ownRentalRecords,
  admin,
});
