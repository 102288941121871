import axios from "axios";
import { message } from "antd";
import { STATUS, ROLE } from "@constants/user.json";
import { adminFetchUsers, adminPurgeUsers } from "./users";

message.config({
  top: 100,
});

const defaultError =
  "There was an error processing your request. Please try again later. If this problem persists, contact your systems administrator.";

// actions
const MODIFY_INIT = "admin/users/MODIFY_INIT";
const MODIFY_ERROR = "admin/users/MODIFY_ERROR";
const MODIFY_SUCCESS = "admin/users/MODIFY_SUCCESS";

// reducer
export const initialState = {
  hasError: false,
  error: "",
  isLoading: false,
};

export default function modify(state = initialState, action = {}) {
  switch (action.type) {
    case MODIFY_INIT:
      return { ...initialState, isLoading: true };
    case MODIFY_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload || defaultError,
      };
    case MODIFY_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

// action creators

export function modifyUserInit() {
  return { type: MODIFY_INIT };
}

export function modifyUserSuccess() {
  return { type: MODIFY_SUCCESS };
}

export function modifyUserError(message) {
  return { type: MODIFY_ERROR, payload: message };
}

export function modifyUser(id, update, callback) {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (!user.isAdmin && !user.isSuper) {
      return message.error("You do not have permission to update users");
    }
    dispatch(modifyUserInit());
    try {
      await axios.patch(`/api/users/${id}`, update);
      message.success("User updated", 3, () => {
        dispatch(adminFetchUsers());
        dispatch(modifyUserSuccess());
        callback && callback();
      });
    } catch (err) {
      message.error(
        (err && err.response && err.response.data && err.response.data.msg) ||
          defaultError,
      );
      dispatch(modifyUserError(err.message));
      dispatch(adminFetchUsers());
    }
  };
}

export function verifyUser(id, callback) {
  return modifyUser(id, { status: STATUS.VERIFIED }, callback);
}

export function unverifyUser(id, callback) {
  return modifyUser(id, { status: STATUS.UNVERIFIED }, callback);
}

export function banUser(id, callback) {
  return modifyUser(id, { status: STATUS.BANNED }, callback);
}

export function rejectUser(id, callback) {
  return modifyUser(id, { status: STATUS.REJECTED }, callback);
}

export function makeSuper(id, callback) {
  return modifyUser(id, { role: ROLE.SUPER_USER }, callback);
}

export function removeSuper(id, callback) {
  return modifyUser(id, { role: ROLE.USER }, callback);
}

export function makeAdmin(id, callback) {
  return modifyUser(id, { role: ROLE.ADMIN }, callback);
}

export function changeStreetRep(id, repId, callback) {
  return modifyUser(id, { streetRep: repId }, callback);
}

export function deleteUser(id, callback) {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (!user.isAdmin && !user.isSuper) {
      return message.error("You do not have permission to update users");
    }
    dispatch(adminPurgeUsers());
    dispatch(modifyUserInit());
    try {
      await axios.delete(`/api/users/${id}`);
      message.success("User deleted", 3, () => {
        dispatch(adminFetchUsers());
        dispatch(modifyUserSuccess());
        callback && callback();
      });
    } catch (err) {
      message.error(
        (err && err.response && err.response.data && err.response.data.msg) ||
          defaultError,
      );
      dispatch(modifyUserError(err.message));
      dispatch(adminFetchUsers());
    }
  };
}
