import { combineReducers } from "redux";

import users from "./users";
import rentalRecords from "./rentalRecords";
import dataSets from "./dataSets";
import dataSetUpload from "./dataSetUpload";
import modifyUser from "./modifyUser";
import modifyRentalRecord from "./modifyRentalRecord";

export default combineReducers({
  users,
  rentalRecords,
  dataSets,
  dataSetUpload,
  modifyUser,
  modifyRentalRecord,
});
