import axios from "axios";
import { message } from "antd";
import validateField from "@utils/validateField";

message.config({
  top: 100,
});

// actions
const INIT = "rentalForm/INIT";
const SUBMISSION_START = "rentalForm/SUBMISSION_START";
const SUBMISSION_ERROR = "rentalForm/SUBMISSION_ERROR";
const SUBMISSION_COMPLETE = "rentalForm/SUBMISSION_COMPLETE";
const SET_VALUE = "rentalForm/SET_VALUE";
const SET_SINGLE_VALIDATION_ERROR = "rentalForm/SET_SINGLE_VALIDATION_ERROR";
const SET_VALIDATION_ERRORS = "rentalForm/SET_VALIDATION_ERRORS";

// reducer
export const initialState = {
  method: "post",
  url: "",
  formValues: {},
  validationSchema: {},
  validationErrors: {},
  isSubmitting: false,
  hasError: false,
  errorMessage: "",
  hasSubmitted: false,
};

export default function rentalForm(state = initialState, action = {}) {
  switch (action.type) {
    case INIT:
      return {
        ...initialState,
        validationSchema: action.payload.validationSchema || {},
        formValues: action.payload.initialValues || {},
        url: action.payload.url,
        method: action.payload.method || "post",
      };
    case SET_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.payload.input]: action.payload.value,
        },
      };
    case SET_SINGLE_VALIDATION_ERROR:
      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          [action.payload.input]: action.payload.error,
        },
      };
    case SET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.payload,
      };
    case SUBMISSION_START:
      return {
        ...state,
        hasError: false,
        errorMessage: "",
        isSubmitting: true,
      };
    case SUBMISSION_ERROR:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isSubmitting: false,
      };
    case SUBMISSION_COMPLETE:
      return {
        ...state,
        formValues: {},
        isSubmitting: false,
        hasError: false,
        errorMessage: "",
        hasSubmitted: true,
      };
    default:
      return state;
  }
}

// action creators
export function initForm({ validationSchema, initialValues, method, url }) {
  return {
    type: INIT,
    payload: { validationSchema, initialValues, method, url },
  };
}

export function setValue(input, value) {
  return { type: SET_VALUE, payload: { input, value } };
}

export function setSingleValidationError(input, error) {
  return { type: SET_SINGLE_VALIDATION_ERROR, payload: { input, error } };
}

export function setValidationErrors(errorReport) {
  return { type: SET_VALIDATION_ERRORS, payload: errorReport };
}

export function submissionStart() {
  return { type: SUBMISSION_START };
}

export function setSubmissionError(message) {
  return { type: SUBMISSION_ERROR, payload: message };
}

export function setSubmissionComplete() {
  return { type: SUBMISSION_COMPLETE };
}

// thunks

export function handleChange(input, value) {
  return async (dispatch, getState) => {
    const {
      rentalForm: { validationSchema },
    } = getState();
    try {
      dispatch(setValue(input, value));
      await validateField({ [input]: value }, validationSchema, input, value);
      dispatch(setSingleValidationError(input, ""));
    } catch (err) {
      console.error(err);
      dispatch(setSingleValidationError(input, err.message));
    }
  };
}

export function submissionSuccess(msg) {
  return dispatch => {
    message.success(msg, 5, dispatch(setSubmissionComplete()));
  };
}

export function submissionError(msg) {
  return dispatch => {
    message.error(msg, 5, dispatch(setSubmissionError(msg)));
  };
}

export function submitForm(callback) {
  return async (dispatch, getState) => {
    const {
      rentalForm: { method, url, formValues, validationSchema },
    } = getState();
    let isValid = true;
    try {
      await validationSchema.validate(formValues, { abortEarly: false });
      dispatch(setValidationErrors({}));
    } catch (errs) {
      const errorReport = errs.inner.reduce((acc, err) => {
        const field = err.path.split(".")[0];
        acc[field] = err.message;
        return acc;
      }, {});
      dispatch(setValidationErrors(errorReport));
      isValid = false;
    }
    if (isValid) {
      dispatch(submissionStart());
      try {
        const response = await axios[method.toLowerCase()](url, formValues);
        const successMessage =
          (response.data && response.data.msg) || "Your data was submitted.";
        dispatch(submissionSuccess(successMessage));
        callback && callback();
      } catch (error) {
        const errorMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          "There was an error submitting your data, please try again later";
        dispatch(submissionError(errorMessage));
      }
    }
  };
}
