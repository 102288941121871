import axios from "axios";
import yup from "@utils/validator";
import validateField from "@utils/validateField";

// actions
const OPEN_SEARCH = "map/OPEN_SEARCH";
const CLOSE_SEARCH = "map/CLOSE_SEARCH";
const TOGGLE_LEGEND = "map/TOGGLE_LEGEND";
const FETCH_INIT = "map/FETCH_INIT";
const FETCH_ERROR = "map/FETCH_ERROR";
const FETCH_SUCCESS = "map/FETCH_SUCCESS";
const SET_CENTER = "map/SET_CENTER";
const SET_VALUE = "map/SET_VALUE";
const SET_VALIDATION_ERRORS = "map/SET_VALIDATION_ERRORS";

// initial state
const initialState = {
  showSearch: false,
  showLegend: false,
  center: [51.527329, -0.0554895],
  formValues: {},
  validationErrors: {},
  isLoading: false,
  isSubmitting: false,
  hasError: false,
  errorMessage: "",
  markers: [],
};

// validation schema
const validationSchema = yup.object().shape({
  postcode: yup
    .string()
    .postcode()
    .required("Please enter a postcode"),
});

// reducer
export default function map(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_LEGEND:
      return { ...state, showLegend: !state.showLegend };
    case OPEN_SEARCH:
      return { ...state, showSearch: true };
    case CLOSE_SEARCH:
      return { ...state, showSearch: false };
    case SET_CENTER:
      return { ...state, center: action.payload };
    case SET_VALUE:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.payload.input]: action.payload.value,
        },
      };
    case SET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.payload,
      };
    case FETCH_INIT:
      return { ...initialState, showSearch: state.showSearch, isLoading: true };
    case FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error:
          action.payload ||
          "There was an error retrieving reps details, please try again later.",
      };
    case FETCH_SUCCESS:
      return {
        ...initialState,
        showSearch: state.showSearch,
        markers: action.payload || [],
      };
    default:
      return state;
  }
}

// action creators
export function openSearch() {
  return { type: OPEN_SEARCH };
}

export function closeSearch() {
  return { type: CLOSE_SEARCH };
}

export function toggleShowLegend() {
  return { type: TOGGLE_LEGEND };
}

export function markersFetchInit() {
  return { type: FETCH_INIT };
}

export function markersFetchError(err) {
  return { type: FETCH_ERROR, payload: err };
}

export function markersFetchSuccess(markers) {
  return { type: FETCH_SUCCESS, payload: markers };
}

export function setCenter(coords) {
  return { type: SET_CENTER, payload: coords };
}

export function setFormValue(input, value) {
  return { type: SET_VALUE, payload: { input, value } };
}

export function setValidationErrors(errors) {
  return { type: SET_VALIDATION_ERRORS, payload: errors };
}

// thunks
export function fetchMapMarkers() {
  return async dispatch => {
    dispatch(markersFetchInit());
    try {
      const response = await axios.get("/api/rentalRecords?status=verified");
      dispatch(markersFetchSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.msg;
      dispatch(markersFetchError(errorMessage));
    }
  };
}

export function handleChange(input, value) {
  return async dispatch => {
    try {
      dispatch(setFormValue(input, value));
      await validateField({ [input]: value }, validationSchema, input, value);
      dispatch(setValidationErrors({}));
    } catch (err) {
      console.error(err);
      dispatch(setValidationErrors({ postcode: err.message }));
    }
  };
}

export function handleSubmit() {
  return async (dispatch, getState) => {
    const {
      map: {
        formValues: { postcode },
      },
    } = getState();
    try {
      const {
        data: {
          result: { latitude, longitude },
        },
      } = await axios.get(`https://api.postcodes.io/postcodes/${postcode}`);
      // set the center twice to force map to move when searching the
      // same postcode twice after scrolling the map
      dispatch(setFormValue("postcode", ""));
      dispatch(setCenter([latitude - 0.0000001, longitude - 0.0000001]));
      dispatch(setCenter([latitude, longitude]));
      dispatch(closeSearch());
    } catch (err) {
      dispatch(
        setValidationErrors({
          postcode: "Postcode cannot be found, please try another.",
        }),
      );
    }
  };
}
