import React from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import withRedux from "next-redux-wrapper";
import ReduxThunk from "redux-thunk";
import reducer from "@store";
import App from "next/app";
import Head from "next/head";
import Navbar from "@components/Navbar";
import { setUser } from "@store/user";
import * as Sentry from "@sentry/node";

import "antd/dist/antd.css";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "@assets/css/tachyons.css";
import "@components/MapRender/map.css";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  console.info("initialising sentry for error logging");
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

let _composeWithDevTools;

if (process.env.NODE_ENV === "development") {
  const { composeWithDevTools } = require("redux-devtools-extension");
  _composeWithDevTools = composeWithDevTools;
}

function getUserFromRequest(ctx) {
  if (!ctx.req || !ctx.req.user) {
    return {
      isAuthenticated: false,
    };
  }
  const {
    _id,
    // auth0Id,
    name,
    email,
    emailVerified,
    companyName,
    companyAddress,
    role,
    status,
    streetRep,
    membershipNumber,
    termsAgreed,
  } = ctx.req.user;

  // TODO - restrict fields to only those necessary
  const user = {
    isAuthenticated: ctx.req.isAuthenticated(),
    hasCompletedSignup: ctx.req.user.hasCompletedSignup(),
    isVerified: ctx.req.user.isVerified(),
    isBanned: ctx.req.user.isBanned(),
    hasRequestedSuper: ctx.req.user.hasRequestedSuper(),
    isAdmin: ctx.req.user.isAdmin(),
    isSuper: ctx.req.user.isSuper(),
    _id,
    role,
    status,
    // auth0Id,
    name,
    email,
    emailVerified,
    companyName,
    companyAddress,
    streetRep,
    membershipNumber,
    termsAgreed,
  };

  return user;
}

// eslint-disable-next-line no-unused-vars
function makeStore(initialState, options) {
  console.log("making new store");
  if (process.env.NODE_ENV === "development") {
    return createStore(
      reducer,
      initialState,
      _composeWithDevTools(applyMiddleware(ReduxThunk)),
    );
  }
  return createStore(reducer, initialState, applyMiddleware(ReduxThunk));
}

class MyApp extends App {
  // get props on server-side from req object
  // allows us to access session, auth and user data
  static async getInitialProps({ Component, ctx }) {
    const { store, isServer } = ctx;
    if (isServer) {
      store.dispatch(setUser(getUserFromRequest(ctx)));
    }
    let initialProps = {};
    if (Component.getInitialProps) {
      initialProps = await Component.getInitialProps(ctx);
    }
    return { initialProps };
  }

  constructor(props) {
    super(props);
  }

  render() {
    const { Component, initialProps, store, err } = this.props;

    return (
      <Provider store={store}>
        <Head>
          <title>Rent Check</title>
        </Head>
        <Navbar />
        <main className="pt5">
          <Component {...initialProps} err={err} />
        </main>
      </Provider>
    );
  }
}

export default withRedux(makeStore)(MyApp);
