import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { openSearch } from "@store/map";
import Link from "next/link";
import logo from "@assets/logo.png";
import * as Nav from "./Navbar.styles";
import { HamburgerSpin } from "react-animated-burgers";

export default function Navbar() {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const MenuLink = ({ children, href, title, onClick }) => (
    <Link href={href}>
      <a
        className="avenir"
        title={title}
        onClick={() => {
          setIsMenuOpen(false);
          if (typeof onClick === "function") {
            onClick();
          }
        }}
      >
        {children}
      </a>
    </Link>
  );

  return (
    <Nav.Header>
      <Link href="/">
        <Nav.Img src={logo} alt="East End Trades Guild logo" />
      </Link>
      <Nav.HeaderTitle>
        RENT
        <Nav.BoldPink>CHECK</Nav.BoldPink>
      </Nav.HeaderTitle>
      {user && user.isVerified && (
        <Nav.MenuButton onClick={toggleMenu}>
          <HamburgerSpin
            isActive={isMenuOpen}
            toggleButton={toggleMenu}
            buttonColor="#fff"
            barColor="#386a9b"
            buttonStyle={{ transform: "scale(0.75)" }}
          />
        </Nav.MenuButton>
      )}
      {isMenuOpen && (
        <Nav.Menu>
          {user && user.isVerified && (
            <>
              <MenuLink href="/">View Map</MenuLink>
              <MenuLink href="/" onClick={() => dispatch(openSearch())}>
                Search By Postcode
              </MenuLink>
              <MenuLink href="/add-rental-data">Add Rental Data</MenuLink>
              <MenuLink href="/your-account">Your Account</MenuLink>
            </>
          )}
          {(user.isAdmin || user.isSuper) && (
            <MenuLink href="/control-panel">Control Panel</MenuLink>
          )}
          <MenuLink href={"/logout"}>Log Out</MenuLink>
        </Nav.Menu>
      )}
    </Nav.Header>
  );
}
