import axios from "axios";
import { message } from "antd";
import { STATUS } from "@constants/rentalRecord.json";
import { adminFetchRentalRecords } from "./rentalRecords";

message.config({
  top: 100,
});

const defaultError =
  "There was an error processing your request. Please try again later. If this problem persists, contact your systems administrator.";

// actions
const MODIFY_INIT = "admin/rentalRecords/MODIFY_INIT";
const MODIFY_ERROR = "admin/rentalRecords/MODIFY_ERROR";
const MODIFY_SUCCESS = "admin/rentalRecords/MODIFY_SUCCESS";

// reducer
export const initialState = {
  hasError: false,
  error: "",
  isLoading: false,
};

export default function modify(state = initialState, action = {}) {
  switch (action.type) {
    case MODIFY_INIT:
      return { ...initialState, isLoading: true };
    case MODIFY_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload || defaultError,
      };
    case MODIFY_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

// action creators

export function modifyRentalRecordInit() {
  return { type: MODIFY_INIT };
}

export function modifyRentalRecordSuccess() {
  return { type: MODIFY_SUCCESS };
}

export function modifyRentalRecordError(message) {
  return { type: MODIFY_ERROR, payload: message };
}

export function modifyRentalRecord(id, update, callback) {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (!user.isAdmin && !user.isSuper) {
      return message.error(
        "You do not have permission to update rental records",
      );
    }
    dispatch(modifyRentalRecordInit());
    try {
      await axios.patch(`/api/rentalRecords/${id}`, update);
      message.success("Record updated", 3, () => {
        dispatch(adminFetchRentalRecords());
        dispatch(modifyRentalRecordSuccess());
        callback && callback();
      });
    } catch (err) {
      message.error(
        (err && err.response && err.response.data && err.response.data.msg) ||
          defaultError,
      );
      dispatch(modifyRentalRecordError(err.message));
      dispatch(adminFetchRentalRecords());
    }
  };
}

export function verifyRecord(id, callback) {
  return modifyRentalRecord(id, { status: STATUS.VERIFIED }, callback);
}

export function rejectRecord(id, callback) {
  return modifyRentalRecord(id, { status: STATUS.REJECTED }, callback);
}

export function transferRecord(id, transferTo, callback) {
  return modifyRentalRecord(
    id,
    {
      status: STATUS.TRANSFERRED,
      claimedBy: transferTo,
    },
    callback,
  );
}

export function deleteRecord(id, callback) {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (!user.isAdmin) {
      return message.error(
        "You do not have permission to delete rental records",
      );
    }
    dispatch(modifyRentalRecordInit());
    try {
      await axios.delete(`/api/rentalRecords/${id}`);
      message.success("Record deleted", 3, () => {
        dispatch(adminFetchRentalRecords());
        dispatch(modifyRentalRecordSuccess());
        callback && callback();
      });
    } catch (err) {
      message.error(
        (err && err.response && err.response.data && err.response.data.msg) ||
          defaultError,
      );
      dispatch(modifyRentalRecordError(err.message));
      dispatch(adminFetchRentalRecords());
    }
  };
}

export function modifyDataSet(id, update, callback) {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (!user.isAdmin) {
      return message.error(
        "You do not have permission to update rental record data sets",
      );
    }
    dispatch(modifyRentalRecordInit());
    try {
      await axios.patch(`/api/datasets/${id}/rentalRecords`, update);
      message.success("Data set updated", 3, () => {
        dispatch(adminFetchRentalRecords());
        dispatch(modifyRentalRecordSuccess());
        callback && callback();
      });
    } catch (err) {
      message.error(
        (err && err.response && err.response.data && err.response.data.msg) ||
          defaultError,
      );
      dispatch(modifyRentalRecordError(err.message));
      dispatch(adminFetchRentalRecords());
    }
  };
}

export function verifyDataSet(id, callback) {
  return modifyDataSet(id, { status: STATUS.VERIFIED }, callback);
}

export function rejectDataSet(id, callback) {
  return modifyDataSet(id, { status: STATUS.REJECTED }, callback);
}

export function deleteDataSet(id, callback) {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (!user.isAdmin) {
      return message.error(
        "You do not have permission to update rental record data sets",
      );
    }
    dispatch(modifyRentalRecordInit());
    try {
      await axios.delete(`/api/datasets/${id}`);
      message.success("Data set deleted", 3, () => {
        dispatch(adminFetchRentalRecords());
        dispatch(modifyRentalRecordSuccess());
        callback && callback();
      });
    } catch (err) {
      message.error(
        (err && err.response && err.response.data && err.response.data.msg) ||
          defaultError,
      );
      dispatch(modifyRentalRecordError(err.message));
      dispatch(adminFetchRentalRecords());
    }
  };
}

export function adminCreateRecord(record, callback) {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (!user.isAdmin && !user.isSuper) {
      return message.error(
        "You do not have permission to create rental records",
      );
    }
    dispatch(modifyRentalRecordInit());
    try {
      await axios.post(`/api/rentalRecords`, record);
      message.success("New record created", 3, () => {
        dispatch(adminFetchRentalRecords());
        dispatch(modifyRentalRecordSuccess());
        callback && callback();
      });
    } catch (err) {
      message.error(
        (err && err.response && err.response.data && err.response.data.msg) ||
          defaultError,
      );
      dispatch(modifyRentalRecordError(err.message));
      dispatch(adminFetchRentalRecords());
    }
  };
}
