import axios from "axios";
import { message } from "antd";
import { setRequestedSuper } from "./user";
import { STATUS } from "@constants/user.json";

message.config({
  top: 100,
});

// actions
const INIT = "requestRep/INIT";
const SUCCESS = "requestRep/SUCCESS";
const ERROR = "requestRep/ERROR";

// initial state
const initialState = {
  isLoading: false,
  hasError: false,
  error: "",
};

// reducer
export default function requestRep(state = initialState, action = {}) {
  switch (action.type) {
    case INIT:
      return { ...initialState, isLoading: true };
    case SUCCESS:
      return { ...initialState };
    case ERROR:
      return { ...initialState, hasError: true, error: action.payload };
    default:
      return state;
  }
}

// action creators
export function requestRepInit() {
  return { type: INIT };
}

export function requestRepSuccess() {
  return { type: SUCCESS };
}

export function requestRepError() {
  return { type: ERROR };
}

// thunks
export function submissionSuccess() {
  return dispatch => {
    message.success("Thank you! Your request has been received.", 5, () => {
      dispatch(setRequestedSuper());
      dispatch(requestRepSuccess());
    });
  };
}

export function submissionError(err) {
  return dispatch => {
    message.error(
      "There was a problem processing your request. Please try again later",
      5,
      dispatch(requestRepError(err)),
    );
  };
}

export function requestStreetRep(id) {
  return async dispatch => {
    dispatch(requestRepInit());
    try {
      await axios.patch(`/api/users/${id}`, {
        status: STATUS.AWAITING_SUPER,
      });
      dispatch(submissionSuccess());
    } catch (err) {
      dispatch(submissionError(err));
    }
  };
}
