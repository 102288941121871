import styled from "styled-components";

export const Header = styled.header.attrs({
  className: "z-max",
})`
  width: 100vw;
  border-bottom: 1px solid #386a9b;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  background: white;
`;

export const Img = styled.img`
  height: 2rem;
  margin-left: 1rem;
  @media all and (min-width: 400px) {
    height: 3rem;
  }
`;

export const HeaderTitle = styled.div.attrs({
  className: "f4 f3-ns avenir",
})`
  position: absolute;
  width: calc(100vw);
  text-align: center;
  pointer-events: none;
`;

export const BoldPink = styled.span.attrs({
  className: "fw7 dark-pink",
})``;

export const MenuButton = styled.div`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  outline: none;
`;

export const Menu = styled.nav.attrs({
  className: "z-max",
})`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: calc(100vh - 4rem);
  top: 4rem;
  left: 0;
  z-index: 999;
  padding: 0 1rem;
  background: #0e4762;
  color: #fff;
  & > a {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    color: #fff !important;
    :active,
    :hover,
    :focus {
      text-decoration: underline;
    }
  }
`;
